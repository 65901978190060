/* Aphrodite Form */

#{$aph-form} {
    &__helper {
        @include font($font-sm);
    }

    &__label {
        margin-bottom: 0;
    }

    &__field {
        margin-bottom: 15px;
    }

    #{$aph-form} {
        &__control {
            &-with-icon {
                &__button {
                    width: 40px;
                }

                #{$aph-form} {
                    &__control {
                        padding-right: 40px;
                    }
                }
            }

            &[type="radio"],
            &[type="checkbox"] {
                + #{$aph-form} {
                    &__label {
                        padding: 0 15px 0 30px;
                    }
                }
            }

            &[type="checkbox"]:checked {
                + #{$aph-form} {
                    &__label:before {
                        background-image: url("../../icons/icon--check--black.svg");
                    }
                }
            }
        }
    }

    @include xs {
        &__label,
        &__helper {
            @include font($font-md);
        }

        #{$aph-form} {
            &__control {
                &-with-icon {
                    &__button {
                        width: 45px;
                    }

                    #{$aph-form} {
                        &__control {
                            padding-right: 45px;
                        }
                    }
                }
            }
        }

        select#{$aph-form}__control,
        select#{$aph-form}__control:active,
        select#{$aph-form}__control:focus {
            text-align: left !important;
            box-shadow: none;
            border: 1px solid hsla(204, 6%, 84%, 0.4);
        }

        select#{$aph-form}__control:focus {
            border-color: #d4d7d9;
        }
    }
}
