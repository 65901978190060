/* footer */
#{$footer} {
    text-align: center;

    @include font($font-sm, $line-md, null);

    &__item {
        display: inline-block;
        vertical-align: middle;
        text-decoration: none;

        padding: 0 #{$container-md-padding / 2};

        @include font(null, null, $weight-md);

        color: inherit;
        outline: 0;
        border: 0;

        &:hover,
        &:active,
        &:focus,
        &:visited {
            color: inherit;
            outline: 0;
            border: 0;
        }
    }

    &__copyright {
        margin-top: #{$container-md-padding / 2};
    }

    @include xs {
        @include font($font-md, null, null);

        &__item {
            padding: 0 #{$container-md-padding / 3};
        }
    }
}
