/* segment */
#{$segment} {
    padding: $container-md-padding;

    color: $color-black;
    background: $color-white;
    box-shadow: 0 0 8px 0 rgba($color-black, 0.2);
    border-radius: $radius-lg;

    overflow: hidden;

    &__content {
        display: block;
        transition: opacity linear 0.5s;

        &__limited {
            margin: 0 auto;

            width: 100%;
            max-width: 300px;
        }
    }

    &--padded {
        &-xs {
            padding: $container-xs-padding $container-sm-padding;
        }

        &-sm {
            padding: $container-sm-padding $container-md-padding;
        }

        &-lg {
            padding: $container-lg-padding $container-md-padding;
        }

        &-xs,
        &-sm,
        &-lg {
            &#{$segment} {
                &--no-bs {
                    padding-top: 0;
                    padding-bottom: 0;
                }
            }
        }
    }

    &--no-bs {
        box-shadow: none;
    }

    @include xs {
        &--padded {
            &-xs,
            &-sm,
            &-md,
            &-lg {
                padding-right: $container-md-padding;
                padding-left: $container-md-padding;
            }
        }
    }
}
