/* 'user confirm' styles */
#{$confirm} {
    &__pic {
        display: block;
        margin: 0 auto;
        padding: 0;
        width: 220px;
        height: 220px;

        text-align: center;
        @include font(null, 220px);

        color: $color-light-grey;
        background: $color-white-smoke;
        border-radius: 50%;

        transition: padding 0.15s linear;

        &--unloaded {
            padding: 80px;
            fill: $color-primary;
        }
    }

    &__name {
        margin: -3px 0 3px;
        @include font(20px, 30px, $weight-md);
    }
}
