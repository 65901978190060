/* Aphrodite Titles Overrides */

h1,
h2,
h4 {
    &#{$aph} {
        @include font(null, null, $weight-sm);
    }
}

h3 {
    &#{$aph} {
        @include font(20px, 20px, $weight-md);
    }
}

h4 {
    &#{$aph} {
        @include font(20px, 30px);
        margin: 7px 0 3px;
    }
}
h5 {
    &#{$aph} {
        @include font(14px);
        margin: 0.965em 0;
    }
}
