/* Aphrodite Lists */

#{$aph-list} {
    font-size: 16px;
    line-height: 20px;

    &__header {
        color: $color-black;
        text-transform: none;
    }

    &--bulleted {
        padding-left: 0;

        #{$aph-list} {
            &__item {
                padding-left: 10px;

                &:before {
                    margin-left: -10px;
                }
            }
        }
    }
}
