/* Aphrodite Buttons */

#{$aph-btn} {
    line-height: 20px;
    font-weight: 500;
    padding: 10px 15px;
    border-radius: 80px;

    #{$icon} {
        margin-top: -5px;
        margin-right: 5px;
        margin-bottom: -2px;
    }

    &--link {
        @include font(null, null, $weight-sm);

        &.aph.btn {
            background: transparent !important;
            background-color: transparent !important;
        }

        &:not(:disabled) {
            &:hover,
            &:focus,
            &:active {
                color: $color-secondary;
            }
        }
    }

    @each $key, $value in $color-defaults {
        &--#{$key} {
            background: $value;

            &:hover,
            &:active,
            &:focus {
                background: darken($value, 8%);
            }
        }
    }

    &--bordered {
        line-height: 18px;

        &#{$aph-btn} {
            @each $key, $value in $color-defaults {
                &--#{$key} {
                    color: $value;
                    border-color: $value;
                    background: transparent;

                    &:hover,
                    &:active,
                    &:focus {
                        color: darken($value, 8%);
                        border-color: darken($value, 8%);
                        background: transparent;
                    }
                }
            }
        }
    }

    &--sm-text {
        @include font(12px, 20px);

        &#{$aph-btn} {
            &--bordered {
                @include font(null, 18px);
            }
        }
    }

    @include xs {
        &--sm-text {
            padding: 0;
        }
    }

    @include xxs {
        &--sm-text {
            @include font(16px);
        }
    }
}
