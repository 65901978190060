/* Aphrodite Container Override */

#{$aph-container} {
    padding-right: $container-md-padding;
    padding-left: $container-md-padding;
    max-width: $container-md;

    &.lg {
        max-width: $container-lg;
    }

    &.sm {
        max-width: $container-sm;
    }

    &.xs {
        max-width: $container-xs;
    }

    @include xs {
        padding-right: $container-xs-padding;
        padding-left: $container-xs-padding;
    }
}
