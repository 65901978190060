/* oauth */
#{$oauth} {
    &__modal {
        color: $color-dark-grey;
        margin-left: 50px;
        margin-right: 50px;

        &__header {
            background-color: $color-white-smoke;
            padding-top: 10px;
            padding-bottom: 10px;
            margin-bottom: 10px;
            border-radius: 10px;
            font-weight: bold;
            text-align: center;
            
            &__name {
                color: $color-primary;
            }
        }

        a {
            color: $color-info;
            font-weight: bold;
            text-decoration: none;
        }
    }
}
