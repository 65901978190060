// bases
$color-white: #ffffff;
$color-white-smoke: #f8f8f8;

$color-light-grey: #d4d7d9;
$color-grey: #9da6ad;
$color-dark-grey: #7a8085;

$color-black-grey: #2d2d2d;
$color-black: #000000;

// colors
$color-blue: #00a5db;
$color-green: #60c659;
$color-purple: #a35fb1;
$color-red: #f13335;
$color-orange-red: #f8561f;
$color-orange: #fca311;
$color-orange-2: #fea400;
$color-orange-1: #ff8d50;
$color-yellow: #ffc61e;

// socials
$color-facebook: #3b5998;
$color-twitter: #6ec7e3;
$color-wpp: #25d366;

// defaults
$color-inverse: $color-white;
$color-primary: $color-orange;
$color-secondary: $color-blue;
$color-success: $color-green;
$color-warning: $color-yellow;
$color-error: $color-red;
$color-info: $color-blue;

$color-defaults: (
    inverse: $color-inverse,
    primary: $color-primary,
    secondary: $color-secondary,
    success: $color-success,
    warning: $color-warning,
    error: $color-error,
    info: $color-info,
    wpp: $color-wpp,
);
