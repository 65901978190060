/* company */
#{$company} {
    text-align: center;

    &__btn {
        @include font(16px, 20px, 400);

        width: 100%;
        margin: 5px 0;
        padding: 10px;
        border: 0;
        outline: 0;

        min-height: 110px;

        border-radius: 8px;
        background: transparent;

        transition:
            color 0.25s,
            background 0.5s;

        &:hover,
        &:focus,
        &:active {
            background: transparent;
        }
    }

    &__pic {
        display: block;
        margin: 0 auto;
        padding: 0;

        width: 80px;
        height: 80px;

        border-radius: 8px;

        transition: padding 0.25s;

        &--unloaded {
            padding: 15px;
            background: rgba($color-secondary, 0.22);
        }
    }

    &__title {
        margin: 10px 0 0;
    }

    @include xs {
        &__btn {
            padding-right: 0;
            padding-left: 0;

            &:hover,
            &:focus,
            &:active {
                background: transparent;
            }
        }

        &__pic {
            margin-bottom: 0;
        }

        &__title {
            margin: 8px 0 -3px;

            @include font(12px, 15px);
        }
    }
}
