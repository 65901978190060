/* 'lazy load' styles */
#{$lazy} {
    border-radius: 4px;
    background-color: $color-white-smoke;

    @each $size in $margin-sizes {
        &--#{$size} {
            min-height: #{$size}px;
        }
    }
}
