.choose-language-container {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
}

.select-container {
    margin-bottom: 0.5rem;
    cursor: pointer;
}

.options {
    position: absolute;
    background: #fff;
    margin-top: 0.3rem;
    padding: 0.5rem;
    border-radius: 8px;
    box-shadow: 0px 0px 20px 0px #30495f1a;
    z-index: 1;
}

.option,
.selected-option {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    padding: 0.5rem;
    cursor: pointer;
}

.option:hover {
    background-color: #f4f6f8;
    border-radius: 4px;
}

.option-image {
    width: 1.5rem;
    height: 1.5rem;
}

.arrow-select-open {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}
