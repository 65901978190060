#{$mfa} {
    display: block;

    &__uri-input {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border: 1px solid #D9D9D9;
        border-radius: 4px;

        & > input {
            width: 100%;
            height: 100%;
            border: none;
            padding-left: 10px;
            color: #636262;
            user-select: text;
            cursor: text;
        }

        & > button {
            background-color: #FFF;
            border: none;
            line-height: 0;
            padding: 4px 8px;
        }
    }
}
