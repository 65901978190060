/* load */
#{$load} {
    z-index: 10;
    position: relative;
    overflow: hidden;

    min-width: 120px;
    min-height: 80px;

    &:before,
    &__icon {
        content: " ";
        opacity: 0;
        position: absolute;
        animation: opacityOut 0.15s linear;
        transition: opacity 0.15s linear;
    }

    &:before {
        display: block;
        z-index: -10;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        background: rgba($color-white, 0.75);
    }

    &__icon {
        display: none;
        z-index: -10;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        width: 80px;
        height: 60px;

        fill: $color-primary;
    }

    &--active {
        &:before,
        #{$load}__icon {
            opacity: 1;
        }

        &:before {
            z-index: 20;
        }

        #{$load}__icon {
            z-index: 30;
        }

        .segment__content {
            opacity: 0.1;
        }
    }

    #{$load} {
        &__icon {
            display: block;
        }
    }
}

@include keyframe("opacityOut") {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}
