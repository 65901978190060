/* icon */
#{$icon} {
    display: inline-block;
    vertical-align: middle;

    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;

    &--facebook {
        width: 25px;
        height: 25px;
        background-image: url("../icons/facebook-f--white.svg");
    }
}
