/* providers */
#{$providers} {
    &__item {
        margin: 5px 12px;
        display: inline-block;
        vertical-align: middle;
        opacity: 1;

        width: 60px;
        height: 60px;

        color: $color-white;

        outline: 0;
        border: 0;

        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;

        transition: opacity 0.25s linear;

        &:hover,
        &:focus,
        &:active {
            outline: 0;
            border: 0;

            opacity: 0.5;
        }
    }

    @include xs {
        &__item {
            width: 50px;
            height: 50px;

            margin-right: 8px;
            margin-left: 8px;

            &.outlook {
                background-size: 50px;
            }
        }
    }
}
