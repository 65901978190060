/* Aphrodite Navbar Overrides */
#{$aph-navbar} {
    &__nav {
        #{$aph-navbar} {
            &__brand {
                &__logo {
                    margin: -3px 5px 0 0;

                    width: 30px;
                    height: 30px;
                    background-size: 30px;
                }
            }
        }
    }

    @include xs {
        box-shadow: none;

        &__nav {
            #{$aph-navbar} {
                &__brand {
                    &__logo {
                        margin: -6px 0 0;

                        width: 40px;
                        height: 40px;
                        background-size: 40px;
                    }
                }
            }
        }
    }
}
