/* Aphrodite Container Override */

#{$aph-col} {
    @include xxs {
        &-xxs {
            &-12 {
                -ms-flex-preferred-size: 100%;
                flex-basis: 100%;
                max-width: 100%;
            }

            &-6 {
                -ms-flex-preferred-size: 50%;
                flex-basis: 50%;
                max-width: 50%;
            }
        }
    }
}
