/* App */
#{$app} {
    overflow: auto;
    overflow-x: hidden;
    overflow-y: auto;

    &__header {
        min-height: 60px;
        max-height: 60px;
    }

    &__content {
        min-height: calc(100% - 180px);
    }

    &__footer {
        min-height: 120px;
        max-height: 120px;

        background: $color-white-smoke;
    }
}
