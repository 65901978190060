/* base */
html {
    height: 100%;
}

html,
body {
    width: 100%;
}

body {
    margin: 0;
    padding: 0;
    min-height: 100%;
    background: $color-white;

    font-family: "Roboto", sans-serif;
    @include font($font-md, $line-md, null);
}

html,
.auth {
    color: $color-white;
    background: linear-gradient(
        315deg,
        $color-orange-red 0%,
        $color-orange-2 100%
    );
}

#root {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.w-full {
    width: 100%;
}

.text-transform-none,
.aph.text-transform-none {
    text-transform: none;
}

.separator {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: $color-grey;

    &:before,
    &:after {
        content: " ";
        display: flex;
        flex: 1;
        height: 1px;
        background: $color-light-grey;
    }

    &:before {
        margin-right: 10px;
    }

    &:after {
        margin-left: 10px;
    }
}

.otp {
    &__btn,
    &__btn.aph,
    &__separator,
    &__container {
        width: 100%;
        max-width: 260px;
        margin-right: auto;
        margin-left: auto;
    }

    &__input,
    &__input.aph {
        max-width: 175px !important;
        margin-left: auto !important;
        margin-right: auto !important;

        font-family: monospace !important;
        font-size: 20px !important;
        letter-spacing: 5px !important;
    }
}

.input-red {
    box-shadow: inset 0 0 4px 0 $color-red !important;
}
