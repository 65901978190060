/* auth */
#{$auth} {
    transition: opacity linear 0.5s;
    animation: opacityIn 0.5s ease-in;

    &__header {
        min-height: 80px;
        max-height: 80px;

        &__brand {
            display: inline-block;
            @include font($font-lg, $line-lg, $weight-sm);

            color: inherit;
            border: 0;
            outline: 0;
            text-decoration: none;

            padding: 15px 0 15px 40px;

            background-position: left center;
            background-repeat: no-repeat;
            background-size: 30px;
            background-image: url("../images/application-logo--inverse.svg");

            &:hover,
            &:focus,
            &:active,
            &:visited {
                border: 0;
                outline: 0;
            }
        }
    }

    &__footer {
        min-height: 100px;
        max-height: 100px;
    }

    @include xs {
        &__header {
            text-align: center;

            &__brand {
                @include font(32px, 40px);

                display: inline-block;
                padding-left: 50px;
                background-size: 40px;
            }
        }
    }

    .wrapper-wrong-email{
        display: flex;
        gap: 0.2rem;
        margin: 0.2rem 0 0.7rem 0;

        & > span {
            font-family: Roboto;
            font-size: 12px;
            font-weight: 400;
            line-height: 14.06px;
            text-align: left;
            color: #DE3618;
        }
    }

    .wrapper-attempts {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: flex-start;

        .wrapper-msg-alert{
            display: flex;
            gap: 0.2rem;
            margin: 0.2rem 0 0.7rem 0;

            & > span, strong {
                font-family: Roboto;
                font-size: 12px;
                font-weight: 700;
                line-height: 14.06px;
                text-align: left;
                color: #DE3618;
            }

            & .msg-attempts {
                font-weight: 400;
                margin-left: 0.2rem;
            }
        }

        .wrapper-msg-info{
            display: flex;
            gap: 0.2rem;

            & > span {
                font-family: Roboto;
                font-size: 12px;
                font-weight: 500;
                line-height: 14.06px;
                text-align: left;
                color: #7A8085;
                line-height: 16px;
            }

            .recovery-link {
                font-family: Roboto;
                font-size: 12px;
                line-height: 14.06px;
                text-align: left;
                color: #00A5DB;
                font-weight: 700;
                margin: 0 0.2rem;

                &:hover {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
    }
}

@include keyframe("opacityIn") {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
