/* CSS */
.tooltip-container {
    text-align: left;
    margin-left: 4px;
    display: inline-block;
    cursor: pointer;
}

.tooltip {
    transform: translateX(-52%);
    visibility: hidden;
    margin: 0;
    box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.12);
    line-height: normal;
    color: #dfe3e8;
    font-size: 14px;
    width: 231px;
    height: 274px;
    background-color: #2d2d2d;
    text-align: left;
    border-radius: 4px;
    padding: 16px;
    padding-top: 0px;
    padding-bottom: 0px;
    position: absolute;
    flex-shrink: 0;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.5s ease;
}

.tooltip-container:hover .tooltip {
    visibility: visible;
    opacity: 1;
}

@media (max-width: 600px) {
    .tooltip {
        height: 280px;
    }
}
